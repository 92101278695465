.header {
	padding: 15px 20px;
	&__row {
		margin: -10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__col {
		margin: 10px;  
	}
}