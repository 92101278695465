h1, h2, h3, h4, h5, h6, .title, .subtitle {
	color: $dark-black-primary; // ie
	color: var(--black-primary);
}

h1 {
	margin: 0 0 1rem;
}

h1, h2, .title {
	font-size: 36px;
	padding-bottom 5px;
	border-bottom: 2px solid;
}

h2, .title {
	margin: 4rem 0 1rem;
	&:first-of-type {
		margin-top: 0px;
	}
}

h3 {
	font-size: 28px;
	font-weight: normal;
	margin: 2rem 0 1rem;
}

h4, h5, h6, .subtitle {
	font-size: 22px;
	font-weight: normal;
	margin: 2rem 0 1rem;
}

.title-permalink {
	opacity: .3;
	color: inherit;
	white-space: nowrap;
	text-decoration: none;
	&:hover {
		opacity: 1;
	}
}

p, ul, ol, blockquote {
	margin: 1rem 0px;
}

li + li {
	margin: 10px 0px 0px;
}

a {
	color: $blue-primary; // ie
	color: var(--blue-primary);
	transition: all $transition-primary;
	@media (hover: hover) {
		&:active {
			active()
		}

		&:not([class]) {
			&:hover {
				text-decoration: none;
			}
		}
	}
}

.table-wrapper {
	width: 100%;
	margin: 1rem 0 2rem;
	overflow-x: auto;
	scroll-shadow-x()
}

table {
	width: 100%;
	text-align: left;
	border-spacing: 0px;
	border-collapse: collapse;
}

th, td {
	padding: 15px;
	min-width 170px;
	border-width: 2px;
	border-style: solid;
	border-color: $dark-border-primary; // ie
	border-color: var(--border-primary);
}

blockquote {
	color: #fff;
	font-size: 18px;
	font-style: italic;
	padding: 20px;
	background-color: $dark-brand; // ie
	background-color: var(--brand);
	&:before,
	&:after {
		display: block;
		font-size: 28px;
		line-height: normal;
	}

	&:before {
		content: '❝';
		text-align: left;
	}

	&:after {
		content: '❞';
		text-align: right;
	}
}

pre[class*="language-"] {
	border-radius: $radius-primary;
	margin: 1rem 0 2rem !important;
}

code:not([class]) {
	font-family: Consolas,Monaco,'Andale Mono','Ubuntu Mono',monospace;
	font-size: 1em;
	white-space: nowrap;
	// ie
	color: $dark-black-primary;
	text-shadow: 0 1px $dark-bcg-primary;

	color: var(--black-primary);
	text-shadow: 0 1px var(--background-primary);

	padding: 1px 5px;
	border-radius: $radius-primary;
	background-color: $dark-bcg-code; // ie
	background-color: var(--background-code);
}


// <= 480px
+media-phone-s() {
	h1, h2, .title {
		font-size: 28px;
		border: 0px;
		margin: 2rem 0 1rem;
	}

	h3 {
		font-size: 22px;
	}

	h4, h5, h6, .subtitle {
		font-size: 18px;
	}

	ul, ol {
		padding-left: 20px;
	}

	pre[class*="language-"] {
		border-radius: 0px;
		margin-right: -20px !important;
		margin-left: -20px !important;
	}
}
