.changelog {
    &__item {
        & + ^[0]__item {
            margin: 60px 0px 0px;
            padding: 60px 0px 0px;
            border-style: solid;
            border-width: 2px 0px 0px;
            border-color: $dark-border-primary;
            border-color: var(--border-primary);        
        }    
    }

    &__title {
        font-size: 26px;
        font-weight: bold;
        margin: 0px 0px 20px;
    }
}