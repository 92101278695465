// <= 1440px
media-desktop-m(resolution = $lg_m)	
	@media screen and (max-width: resolution) 
		{block}

// <= 1280px
media-desktop-s(resolution = $lg_s)
	@media screen and (max-width: resolution) 
		{block}

// <= 1024px
media-desktop-xs(resolution = $lg_xs)	
	@media screen and (max-width: resolution) 
		{block}


// <= 980px
media-table-m(resolution = $medium_m)		
	@media screen and (max-width: resolution) 
		{block}	

// <= 768px
media-table-s(resolution = $medium_s)			
	@media screen and (max-width: resolution) 
		{block}


// <= 650px
media-phone-m(resolution = $small_m)	
	@media screen and (max-width: resolution) 
		{block}	

// <= 480px
media-phone-s(resolution = $small_s)	
	@media screen and (max-width: resolution) 
		{block}	

// <= 380px
media-phone-xs(resolution = $small_xs)	
	@media screen and (max-width: resolution) 
		{block}		