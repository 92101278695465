.form-build {
	&__row {
		font-size: 18px
		column-count: 4
	}

	&__col {
		& + ^[-1]__col {
			margin: 20px 0px 0px
		}
	}

	&__footer {
		margin: 50px 0px 0px
	}


	// <= 980px
    +media-table-m() {
		&__row {
			column-count: 3
		}
	}

	// <= 650px
	+media-phone-m() {
		&__row {
			column-count: 2
		}
	}

	// <= 480px
	+media-phone-s() {
		&__row {
			column-count: 1
		}

		&__footer {
			margin: 30px 0px 0px
		}
	}
}
