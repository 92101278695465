.readmore {
    max-height: 200px;
    &-trigger {
        color: $dark-brand; // ie
        color: #fff;
        border: 0px;
        border-radius: 100px;
        padding: 10px 15px;
        margin: 10px 0px 0px;
        background-color: $dark-brand; // ie
        background-color: var(--brand);
        [data-color-scheme="dark"] & {
            color: $dark-brand; // ie
            color: var(--brand);
            background-color: #fff;
        }
    }
}