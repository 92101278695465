.slideout {
    &-wrapper {
        background-color: $overlay-primary; // ie
        background-color: var(--overlay-primary);
    }

    &-content {
        width: 320px;
        padding: 20px 0px;
        margin-left: auto;
        background-color: $dark-bcg-primary; // ie
        background-color: var(--background-secondary);

        border-style: solid;
        border-width: 0px 0px 0px 2px;
        border-color: $light-basicui-border; // ie
        border-color: var(--basicui-border);

        transform: translateX(320px);
        transition: transform $transition-primary;
    }

    &-close {
        fill: #fff;
        font-weight: bold;
        width: 40px;
        height: 40px;
        padding: 5px;
        margin-left: auto;
        border-radius: 100px;
        background-color: $red-primary;
        border: 2px solid $red-primary;
        
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 16px;
            height: 16px;
        }
    }

    &-header {
        padding: 0px 20px;
    }

    &-main {
        padding: 0px 20px;
        margin: 20px 0px 0px;
    }

    &--show {
        .slideout-content {
            transform: translateX(0);
        } 
    }
}