// default
.slider {
	width: 100%;
	overflow: hidden;
	position: relative;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}


.splide {
	&__arrow {
		position: absolute;
		
		opacity: .8;
		z-index: 2;
		width: 50px;
		height: 50px;
		border: 0px;
		border-radius: 100px;

		display: flex;
		align-items: center;
		justify-content: center;

		background-color: $dark-brand; // ie
        background-color: var(--brand);
        [data-color-scheme="dark"] & {
            background-color: #fff;
			svg {
				margin: auto;
				fill: $dark-brand; // ie
            	fill: var(--brand);
			}
        }

		&[disabled] {
			visibility: hidden;
		}

		&--prev {
			left: 20px;
			svg {
				transform: rotate(180deg);
			}
		}

		&--next {
			right: 20px;
		}

		&:hover {
			opacity: 1;
		}

		svg {
			fill: #fff;
			width: 20px;
			height: 20px;
		}
	}

	&__pagination {
		.slider & {
			left: 0px;
			right: 0px;
			bottom: 10px;
			position: absolute;

			margin: auto;
			padding: 0px;
		}

		li {
			.slider & {
				margin: 5px;
			}
		}

		&__page {
			width: 15px;
			height: 15px;
			border: 0px;
			padding: 0px;
			border-radius: 100px;
			background-color: $light-bcg-primary; // ie
			background-color: var(--basicui-background-primary);
			&.is-active {
				transform: scale(1.5);
				background-color: $light-basicui-active; // ie
				background-color: var(--basicui-active);
			}
		}
	}

}


// thumbs	
.slider-thumbs {
	position relative;
	margin: 10px 0px 0px;
	.splide {
		&__slide {
			cursor: pointer;
			overflow: hidden;
			border: 2px solid transparent;
			transition: all $transition-primary;
			&--no-pic {
				background-color: $light-brand; // ie
				background-color: var(--brand);
			}

			&.is-active {
				border-color: $light-brand; // ie
				border-color: var(--brand);
			}
		}

		&__arrows {
			&+.splide__track {
				margin: auto;
				padding: 0px 60px;
			}
		}

		&__arrow {
			width: 40px;
			height: 40px;
			&--prev {
				left: 0px;
			}

			&--next {
				right: 0px;
			}
		}
	}
}