.popup {
	&-overlay {
		background-color: $overlay-primary; // ie
    	background-color: var(--overlay-primary);
	}

    &-modal {
		position: relative;
        padding: 35px 20px 20px;
        border-radius: $radius-primary;

        border-style: solid;
		border-width: 2px;
        border-color: $dark-basicui-border; // ie
        border-color: var(--basicui-border);

        background-color: $dark-bcg-primary; // ie
        background-color: var(--basicui-background-primary);

        transform: translateY(5px);
        transition: transform $transition-primary;
        .popup--show & {
            transform: translateY(0px);
        }
    }

    &-close {
        top: 0;
        right: 0;
        position: absolute;

        font-size: 18px;
        fill: $red-primary; // ie
        fill: var(--red-primary);

        display: flex;
        align-items: center;
        justify-content: center;

        border: 0px;
        width: 30px;
        height: 30px;
        padding: 0px;
        border-radius: $radius-primary;
        background-color: transparent;
        @media(hover: hover) {
            &:hover {
                fill: #fff;
                background-color: $red-primary; // ie
                background-color: var(--red-primary);
            }
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }
}
