.note {
    &-info {
        display: flex;
        align-items: center;

        color: #fff;
        font-size: 16px;
        padding: 15px 20px;
        margin: 1rem 0 2rem;
        border-radius: $radius-primary;
        background-color: $blue-primary; // ie
        background-color: var(--blue-primary);
        &:before {
            content: '';
            height: 32px;
            width: 32px;
            min-width: 32px;
            display: block;
            margin: 0px 15px 0px 0px;
            background: inline('icon-info.svg') no-repeat;
            background-size: cover;    
        }

        p {
            margin: 0px;
        }

        a {
            color: #fff;
        }
    }


    // <= 480px
    +media-phone-s() {
        &-info {
            flex-direction: column;
            &:before {
                margin: 0px 0px 10px;
            }
        }
    }
}