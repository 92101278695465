.nav {
	display: flex;
	margin: -10px;
	font-size: 18px;
	&__item {
		margin: 10px;
		text-align: center;
		white-space: nowrap;    
		color: $blue-primary; // ie
		color: var( --blue-primary);
	}

	&-soon {
		color: $dark-disabled-secondary; // ie
		color: var(--disabled-primary);
		pointer-events: none;
		text-decoration: none;

		display: flex;
		align-items: center;
		&:after {
			content: 'Скоро';
			color: #fff;
			font-size: 12px;
			padding: 2px 6px;
			margin-left: 10px;
			display: inline-block;
			border-radius: 100px;
			background-color: $red-primary; // ie
			background-color: var(--red-primary);              
		}
	}
}


// start
.nav-start {
	flex-wrap: wrap;
	
	// <= 480px
	+media-phone-s() {
		.nav {
			&__item {
				flex-grow: 1;
				flex-basis: 100%
			}
		}
	}
}


// list
.nav-list {
	display: block;
	font-size: 24px;
	&__item {
		display: flex;
		position: relative;
		&:hover {
			text-decoration: none;
		}
	}
}

