// theme-default
prism-theme-default() {
    code[class*="language-"],
    pre[class*="language-"] {
        color: black;
	    text-shadow: 0 1px white;
    }


    pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
    code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
        text-shadow: none;
        background: #b3d4fc;
    }

    pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
    code[class*="language-"]::selection, code[class*="language-"] ::selection {
        text-shadow: none;
        background: #b3d4fc;
    }


    :not(pre) > code[class*="language-"],
    pre[class*="language-"] {
        background: #f5f2f0;
    }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
        color: slategray;
    }

    .token.punctuation {
        color: #999;
    }

    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted {
        color: #905;
    }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
        color: #690;
    }

    .token.operator,
    .token.entity,
    .token.url,
    .language-css .token.string,
    .style .token.string {
        color: #9a6e3a;
        /* This background color was intended by the author of this theme. */
        background: hsla(0, 0%, 100%, .5);
    }

    .token.atrule,
    .token.attr-value,
    .token.keyword {
        color: #07a;
    }

    .token.function,
    .token.class-name {
        color: #DD4A68;
    }

    .token.regex,
    .token.important,
    .token.variable {
        color: #e90;
    }
}
// /theme-default


// tomorrow-night
prism-theme-tomorrow-night() {
    code[class*="language-"],
	pre[class*="language-"] {
		color: #ccc;
        text-shadow: none;		
	}

	:not(pre)>code[class*="language-"],
	pre[class*="language-"] {
		background: #2d2d2d;
	}

	.token.comment,
	.token.block-comment,
	.token.prolog,
	.token.doctype,
	.token.cdata {
		color: #999;
	}

	.token.punctuation {
		color: #ccc;
	}

	.token.tag,
	.token.attr-name,
	.token.namespace,
	.token.deleted {
		color: #e2777a;
	}

	.token.function-name {
		color: #6196cc;
	}

	.token.boolean,
	.token.number,
	.token.function {
		color: #f08d49;
	}

	.token.property,
	.token.class-name,
	.token.constant,
	.token.symbol {
		color: #f8c555;
	}

	.token.selector,
	.token.important,
	.token.atrule,
	.token.keyword,
	.token.builtin {
		color: #cc99cd;
	}

	.token.string,
	.token.char,
	.token.attr-value,
	.token.regex,
	.token.variable {
		color: #7ec699;
	}

	.token.operator,
	.token.entity,
	.token.url {
		color: #67cdcc;
	}

	.token.important,
	.token.bold {
		font-weight: bold;
	}

	.token.italic {
		font-style: italic;
	}

	.token.entity {
		cursor: help;
	}

	.token.inserted {
		color: green;
	}
}
// /tomorrow-night


// default
html {
    &:not([data-color-scheme="dark"]) {
        prism-theme-default();
    }

    &[data-color-scheme="dark"] {
        prism-theme-tomorrow-night();
    }
}


// ie
.html-ie {
    pre {
        padding: 20px;
        overflow: auto;
        border-radius: $radius-primary;
        background: $light-bcg-code;
    }
}