/* logotype */
.logotype {
    display: inline-flex;
    align-items: flex-end;
    text-decoration: none;

    fill: #fff; // ie
    fill: var(--brand);            
    [data-color-scheme="dark"] & {
        fill: #fff;
    }
    
    svg {
        height: 128px;
        width: 512px;        
    }

    sup {
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;        
        
        padding: 5px 7px;
        border-radius: 100px;
        display: inline-block;
        background-color: $red-primary; // ie
        background-color: var(--red-primary);
    }

    // header and sidebar
    .header & 
    .sidebar & {
        svg {
            width: 256px;
            height: 64px;
        }

        sup {
            font-size: 12px;			
            line-height: 12px;			
            padding: 3px 5px;
        }
    }


    // <= 768px
	+media-table-s() {
        &
        .header & {
            svg {
                width: 225px;
                height: 68px;
            }
        }
                
        .sidebar & {
            svg {
                width: 128px;
                height: 32px;
            }

            sup {
                font-size: 10px;			
                line-height: 10px;			
            }
        }
    }
}