.select {
	&-header,
	&-dropdown {
        background-color: $dark-bcg-primary; // ie
        background-color: var(--basicui-background-primary);
	}

	&-header {
		padding: 10px;
		border-radius: $radius-primary;
        border-width: 2px;
        border-style: solid;
        border-color: $dark-basicui-border; // ie
        border-color: var(--basicui-border);
		.select--disabled & {
			opacity: .7;
			background-color: #222;
		}
	}

	&-output {
		&__item {
			color: #fff;
			padding: 5px 10px;
			border-radius: $radius-primary;
			background-color: $dark-basicui-active; // ie
			background-color: var(--basicui-active);
		}

		&__clear {
			color: #fff;
			width: 20px;
			height: 20px;
			padding: 0px;
			border: 0px;
			margin-left: 5px;
			border-radius: 100px;
			background-color: $red-primary; // ie
			background-color: var(--red-primary);
		}
	}

	&-clear {
		color: #fff;
		width: 26px;
		height: 26px;
		padding: 0px;
		border: 0px;
		border-radius: 100px;
		background-color: $red-primary; // ie
		background-color: var(--red-primary);
	}

	&-dropdown {
		border-style: solid;
		border-width: 0px 2px 2px 2px;
        border-color: $dark-basicui-border; // ie
        border-color: var(--basicui-border);
		border-radius: 0px 0px $radius-primary $radius-primary;
		box-shadow: $box-shadow-bottom;
    }

	&-search {
		padding: 10px;
		&__field {
			width: 100%;
			padding: 8px 10px;
        }
    }

	&-listbox {
		max-height: 340px;
	}

	&-optgroup {
		margin: 10px;
		border-style: solid;
		border-width: 2px;
		border-color: $dark-basicui-border; // ie
		border-color: var(--basicui-border);
		border-radius: $radius-primary;
	}

	&-label {
		font-size: 16px;
		font-weight: bold;
		background-color: $dark-bcg-secondary; // ie
		background-color: var(--basicui-background-secondary);
		border-radius: $radius-primary $radius-primary 0px 0px;
	}

	&-label,
	&-option,
	&-nofound {
		display: flex;
		align-items: center;

		padding: 10px;
		text-align: left;
    }

	&-option {
		cursor: pointer;
		background-color: transparent;
		&:hover {
			opacity: .7;
			color: #fff;
			background-color: $dark-basicui-active; // ie
			background-color: var(--basicui-active);
			.select-icon {
				fill: #fff;
			}
        }
    }

	&-icon {
		width: 16px;
		height: 16px;
		margin-right: 5px;
		pointer-events: none;
		fill: $dark-black-secondary; // ie
		fill: var(--black-secondary);
	}

	// view
	&-view {
		&--placeholder {
			.select {
				&-output {
					color: $dark-gray-primary; // ie
					color: var(--color-placeholder);
				}
			}
		}
	}

	&--selected {
		color: #fff;
		background-color: $dark-basicui-active; // ie
		background-color: var(--basicui-active);
		.select-icon {
			fill: #fff;
		}
	}

    &--hover {
		opacity: .7;
		color: #fff;
		background-color: $dark-basicui-active;
		.select-icon {
			fill: #fff;
		}
    }

	&--mobile {
		.select {
			&-overlay {
				top: 0px;
				right: 0px;
				bottom: 0px;
				left: 0px;
				position: fixed;

				opacity: 0;
				visibility: hidden;

				z-index: 999;
				padding: 10px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $overlay-primary; // ie
				background-color: var(--overlay-primary);
			}

			&-dropdown {
				top: auto !important;
				position: relative;

				width: 100%;
				max-width: 320px;
				border-width: 2px;
				border-radius: $radius-primary;
			}
		}
	}

	// positions and show
	&--open {
		&.select {
			&--top {
				.select {
					&-header {
						border-radius: 0px 0px $radius-primary $radius-primary;
					}

					&-dropdown {
						border-width: 2px 2px 0px 2px;
						border-radius: $radius-primary $radius-primary 0px 0px;
						box-shadow: $box-shadow-top;
					}
				}
			}

			&--bottom  {
				.select {
					&-header {
						border-radius: $radius-primary $radius-primary 0px 0px;
					}
				}
			}
		}

		.select {
			&-overlay {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
