.range-slider {
	&__main {
		padding: 5px;
		border-radius: 100px;
		background-color: var(--basicui-background-secondary);
	}

	&__selected {
		border-radius: 100px;
		background-color: var(--basicui-active);
	}

	&__pointer {
		top: -5px;
		width: 20px;
		height: 20px;
		border-radius: 100px;
		border: 2px solid #fff;
		background-color: var(--basicui-active);
	}

	&__value {
		padding-top: 10px;
	}
}
