[data-tooltip-content] {
	// ie
	color: $blue-primary;
	text-decoration: underline dashed $blue-primary;

	color: var(--blue-primary);
	text-decoration: underline dashed var(--blue-primary);
}

.tooltip {
	text-align: center;
	transform: translateY(5px);
	transition: transform $transition-primary;
	&-content {
		font-size: 14px;
		line-height: 130%;
		margin: auto;
		padding: 10px;
		display: block;
		max-width: 250px;
		position: relative;
		border-radius: $radius-primary;
        background-color: $dark-bcg-secondary; // ie
        background-color: var(--basicui-background-secondary);
		box-shadow: $box-shadow-primary;
	}
	
	&.top-default {
		left: 0px;
		right: 0px;
		padding: 0px 20px;
		span {
			max-width: 100%;
		}
	}

	&--show {
		transform: translateY(0px);	
	}


	// <= 480px
	+media-phone-s() {
		right: 20px;
		left: 20px;
	}
}


.tooltip-group {
	display: flex;
	flex-wrap: wrap;
	margin: -10px;
	&__item {
		flex-grow: 1;
		margin: 10px;
		color: #fff;
		text-decoration: none;
		// <= 480px
		+media-phone-s() {
			flex-basis: 100%;
		}
	}
}