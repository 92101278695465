.author {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60px;
    height: 60px;
    border-radius: 100px;
    background: $dark-brand; // ie
    background: var(--color-author);
    &__pic {
        width: 52px;
        height: 52px;
        object-fit: contain;
        border-radius: 100px;    
    }
}