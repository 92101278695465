.quantity-selector {
    &__content {
        margin: 0px 5px;
    }

    &__trigger {
		display: flex;
		align-items: center;
		justify-content: center;

        fill: #fff;
        border: 0px;
        width: 42px;
        height: 42px;
        padding: 0px;
        border-radius: 100px;
        background-color: $dark-brand; // ie
        background-color: var(--brand);
        [data-color-scheme="dark"] & {
            fill: $dark-brand; // ie
            fill: var(--brand);
            background-color: #fff;
        }

		&[disabled] {
			background-color: var(--disabled-primary);
		}

		svg {
			width: 16px;
			height: 16px;
		}
    }
}
