input,
select {
    font-family: $font-family;
    color: $dark-brand;

    padding: 10px 20px;
    border-radius: 100px;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
    border-color: $dark-brand; // ie
    border-color: var(--brand);
}

input[type="range"] {
	padding: 0px;
}

select {
    &[multiple] {
        padding: 0px;
        border-radius: $radius-primary;
    }

    option {
        padding: 10px 20px;
    }
}

label {
    .name {
        display: block;
        margin: 0px 0px 8px;
        font-weight: bold;
        color: $dark-black-primary; // ie
        color: var(--black-primary);
    }

    .field {
        display: block;
    }
}
