.footer {
    padding: 20px;
    flex: 0 0 auto;
    &__row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__year {
        margin: 0px;
    }
}