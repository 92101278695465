.text {
    &-blue {
        color: var(--blue-primary);    
    }

    &-success {
        color: $green-primary;
        color: var(--green-primary);
    }

    &-yellow {
        color: $yellow-primary;
        color: var(--yellow-primary);
    }
}