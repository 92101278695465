* {
    box-sizing: border-box;
}

body {
    font-family: $font-family;
    font-size: $font-base;
    line-height: 1.5;
    color: $dark-black-secondary; // ie
    color: var(--black-secondary);
	text-rendering: optimizeSpeed;

    display: flex;
    flex-direction: column;

    margin: 0px;
    min-width: 320px;
    min-height: 100vh;
    background-color: $dark-bcg-primary; // ie
    background-color: var(--background-primary);
}

.html {
    &-popup,
    &-slideout {
        overflow: hidden;
    }
}

.body {
    &-overlay {
        &:after {
            content: '';
            top: 0px;
            left: 0px;
            position: fixed;
            z-index: 8;
            width: 100%;
            height: 100vh;
            display: block;
            background-color: $overlay-primary; // ie
            background-color: var(--overlay-primary);
        }
    }
}

hr {
    margin: 2rem 0px;
    border-width: 1px;
    border-style: solid;
    border-color: $dark-brand; // ie
    border-color: var(--brand);
}

.container {
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
}

.main {
    padding: 20px;
    flex: 1 0 auto;
}

.live-example {
    padding: 20px;
    margin: 1rem 0 2rem;
    border-radius: $radius-primary;
    background-color: $dark-bcg-live-example; // ie
    background-color: var(--background-live-example);
    // <= 480px
    +media-phone-s() {
        margin: 0px -20px;
        border-radius: 0px;
    }
}

.radiogroup {
	&__item + ^[0]__item {
		margin: 15px 0px 0px;
	}
}
