.choice-group {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    &-item {
        margin: 5px;
        white-space: nowrap;
        flex-basis: calc((100% / 5) - 10px);
    }

    &--checked {
        color: #fff !important;
        border-color: $blue-primary !important;
        background-color: $blue-primary !important;
    }

	&--disabled {
		color: #ddd !important;
		border-color: 	  $light-disabled-primary !important;
		background-color: $light-disabled-primary !important;
	}
}
