.radio-button {
	&-label {
		display: flex;
		align-items: center;
		&:active {
            .radio-button {
                &-replace {
                    active()
                }
            }
        }
	}

	&-field {
		&:checked + .radio-button-replace {
			&:before {
				opacity: 1;
				transform: scale(1);
			}
		}

		&:focus + .radio-button-replace {
            box-shadow: 0px 1px 10px 0px rgb(78 78 78);
        }
	}

	&-replace {
		width: 25px;
		height: 25px;
		padding: 2px;
		display: blocwk
		border-radius: 100px;
		margin: 0px 10px 0px 0px;
		background-color: #fff;

		border-width: 2px;
		border-style: solid;
		border-color: $light-brand;
		border-color: var(--brand);

		transition: all $transition-primary;
		&:before {
			content: '';
			width: 100%;
			height: 100%;
			display: block
			border-radius: 100px;
			background-color: $light-brand; // ie
			background-color: var(--brand);

			opacity: 0;
			transform: scale(0);
			transition: all $transition-primary;
		}
	}

	&-content {
		p {
			margin: 0px;
		}
	}
}
