.tab {
    &-nav {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
    }

    &-trigger {
        margin: 0px;
        border: 0px;
        white-space: nowrap;
        background-color: transparent;
        &--selected {
            background-color: $dark-bcg-primary; // ie
            background-color: var(--basicui-background-primary);      
        }
    }

    &-content {
		opacity: 0;
        &--selected {
            opacity: 1;
        }
    }

    &-hidden {
        padding: 20px;
        background-color: $dark-bcg-primary; // ie
        background-color: var(--basicui-background-primary);   
    }
}