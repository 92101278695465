.checkbox {
    &-label {
        display: flex;
        align-items: center;
        position: relative;
        p {
            margin: 0px;
        }

        & ~ ^[-1]-label {
            margin-top: 10px;
        }

		@media (hover: hover) {
			&:active {
				.checkbox {
					&-replace {
						active()
					}
				}
			}
		}
    }

    &-field {
        top: 0px;
        left: 0px;
        position: absolute;
        opacity: 0;
        width: .5px;
        height: .5px;
        &:focus + .checkbox-replace {
            box-shadow: 0px 1px 10px 0px rgb(78 78 78);
        }
    }

    &-replace {
        padding: 3px;
        display: block
        margin: 0px 10px 0px 0px;
        background-color: #fff
        border-radius: $radius-primary;
        transition: all $transition-primary;

        border-width: 2px;
        border-style: solid;
        border-color: $light-brand;
        border-color: var(--brand);
    }

    // types
    &--default {
        .checkbox {
            &-field {
                &:checked + .checkbox-replace {
                    &:before {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            &-replace {
                width: 25px;
                height: 25px;
                &:before {
                    content: ''
                    width: 100%;
                    height: 100%;
                    display: block;
                    border-radius: 2px;
                    background-color: $light-brand; // ie
                    background-color: var(--brand);

                    opacity: 0;
                    transform: scale(0);
                    transition: all $transition-primary;
                }
            }
        }
    }

    &--switch {
        .checkbox {
            &-field {
                &:checked + .checkbox-replace {
                    background-color: $green-primary; // ie
                    background-color: var(--green-primary);
                    &:before {
                        left: 100%;
                        transform: translateX(-100%);
                    }
                }
            }

            &-replace {
                width: 50px;
                height: 25px;
                padding: 0px;
                position: relative;
                border-radius: 100px;
                transition: all $transition-primary;
                &:before {
                    content: ''

                    top: 0px;
                    left: 0px;
                    position: absolute;

                    width: 21px;
                    height: 21px;
                    display: block;
                    border-radius: 100px;
                    background-color: $light-brand; // ie
                    background-color: var(--brand);
                    transition: all $transition-primary;
                }
            }
        }
    }
}
