// light
theme-light() {
    --brand: $light-brand;
    --red-primary: $red-primary;
    --green-primary: $green-primary;
    --yellow-primary: $yellow-primary;
    --blue-primary: $blue-primary;
    --color-placeholder: $light-gray-primary;
    
    --black-primary: $light-black-primary;
    --black-secondary: $light-black-secondary;
    
    --border-primary: $light-border-primary;
    --disabled-primary: $light-disabled-primary;
    --overlay-primary: $overlay-primary;
    
    --background-primary: $light-bcg-primary;
    --background-secondary: $light-bcg-secondary;
    --background-code: $light-bcg-code;
    --background-live-example: $light-bcg-live-example;

    --basicui-color: $light-black-secondary;
    --basicui-active: $light-basicui-active;
    --basicui-border: $light-basicui-border;
    --basicui-background-primary: $light-bcg-primary;
    --basicui-background-secondary: $light-bcg-secondary;
}

// dark
theme-dark() {
    --brand: $dark-brand;
    --red-primary: $red-primary;
    --green-primary: $green-primary;
    --blue-primary: $dark-link-primary;
 
    --black-primary: $dark-black-primary;
    --black-secondary: $dark-black-secondary;
    
    --border-primary: $dark-border-primary;
    --disabled-primary: $dark-disabled-primary;
    --overlay-primary: $overlay-primary;
    
    --background-primary: $dark-bcg-primary;
    --background-secondary: $dark-bcg-secondary;
    --background-code: $dark-bcg-code;
    --background-live-example: $dark-bcg-live-example;

    --basicui-color: $dark-black-secondary;
    --basicui-active: $dark-basicui-active;
    --basicui-border: $dark-basicui-border;
    --basicui-background-primary: $dark-bcg-primary;
    --basicui-background-secondary: $dark-bcg-secondary;
}


// default
:root {
    theme-light();
    &[data-color-scheme="dark"] {
        theme-dark();
    }
}