.welcome {
	text-align: center;
	margin: 0px 0px 20px;
	.logotype {
		margin: 0px 0px 15px;
	}

	.subtitle {
		margin: 0px;
		font-size: 24px;    
	}

	// <= 768px
	+media-table-s() {
		.subtitle {
			font-size: 18px;
		}
	}
	
}
