button, .button {
	font-family: $font-family;
	font-size: $font-base;
	color: $dark-black-secondary;
	color: var(--black-secondary);
	cursor: pointer;
	padding: 10px 20px;
	transition: all $transition-primary;
	&:not([disabled]) {
		@media (hover: hover) {
			&:hover {
				hover()
			}

			&:active {
				active()
			}
		}
	}
}

.button {
	border: 2px solid;
	border-radius: 100px;
	text-decoration: none;

	// <= 480px
	+media-phone-s() {
		width: 100%;
	}
}


// colors
.button {
	&--brand {
		fill: #fff;
		color: #fff;

		// ie
		border-color: $dark-brand;
		background-color: $dark-brand;

		border-color: var(--brand);
		background-color: var(--brand);
		[data-color-scheme="dark"] & {
			fill: $dark-brand  // ie
			color: $dark-brand // ie

			fill: var(--brand);
			color: var(--brand);
			border-color: #fff;
			background-color: #fff;
		}
	}

	&--blue {
		fill: #fff;
		color: #fff;

		// ie
		border-color: $blue-primary;
		background-color: $blue-primary;

		border-color: var(--blue-primary);
		background-color: var(--blue-primary);
	}
}

