.sidebar {
    top: 20px;
    position: sticky;

    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__main {
        overflow-y: auto;
        margin: 20px 0px 0px;
    }

    &__back {
        display: block;
        border: 2px solid;
        padding: 10px 20px;
        border-radius: 100px;
        text-decoration: none;
    }

    &__close {
        width: 32px;
        height: 32px;
        padding: 5px;
        display: none;
        border-radius: 100px;
        margin: 0px 0px 0px 10px;
        background-color: var(--red-primary);
        border: 2px solid var(--red-primary);
        &:before,
        &:after {
            content: '';
            width: 16px;
            height: 2px;
            margin: auto;
            display: block;
            position: relative;
            border-radius: 100px;
            background-color: #fff;
            transform-origin: center;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            margin-top: -2px;
            transform: rotate(-45deg);
        }
    }

    // nav
    &__nav {
        font-size: 16px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        ol {
            list-style: none;
        }

        & > ol {
            margin: 0px;
            padding: 0px;
            font-size: 18px;
            list-style: none;
            ol {
                font-size: 14px;
                padding-left: 12px;
                margin: 6px 0px 0px;
                li + li {
                    margin: 6px 0px 0px;
                }
            }
        }

        li {
            & + li {
                margin-top: 10px;
            }
        }

        a {
            text-decoration: none;
            color: $dark-black-secondary;
            color: var(--black-secondary);
            &:hover {
                text-decoration: underline;
            }
        }
    }
    // /nav

    // <= 980px
    +media-table-m() {
        top: 0px;
        left: 0px;
        bottom: 0px;
        position: fixed;

        margin: 0px;
        width: 100%;
		max-width: 280px;
        max-height: initial;
        z-index: 9999;
        visibility: hidden;
        transform: translateX(-450px);
        background-color: var(--background-primary);
        border-right: 2px solid var(--border-primary);
        transition: visibility .1s, transform $transition-primary;
        &.--show {
            visibility: visible;
            transform: translateX(0);
        }

        &__header {
            padding: 15px;
            border-style: solid;
            border-width: 0px 0px 2px 0px;
            border-color: var(--border-primary);
        }

        &__main {
            padding: 15px;
        }

        &__close {
            display: block;
        }
    }
}
