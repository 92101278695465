.accordion {
    &-item {
        margin: -2px 0px 0px;
        border-width: 2px;
        border-style: solid;
        border-color: $dark-basicui-border; // ie
        border-color: var(--basicui-border);
    }
    
    &-trigger {
        display flex;
        align-items: center;
        justify-content: space-between;

        border: 0px;
        margin: 0px;
        background-color: $dark-bcg-primary; // ie
        background-color: var(--basicui-background-primary);
        &:after {
            content: '+'
            display: block;
            margin-left: auto;       
        }
    }

    &-content {
        background-color: $dark-bcg-primary; // ie
        background-color: var(--basicui-background-primary);
        .content {
            padding: 10px 20px;        
        }    
    }

    &--selected {
        & > .accordion {
                &-trigger {
                    color: #fff;
                    background-color: $dark-basicui-active; // ie
                    background-color: var(--basicui-active);
                    &:after {
                        content: '-';                
                    }            
                }
            }  
    }
}