.dropdown {
    &-menu {
        width: 280px;
        padding: 20px;
        border-radius: $radius-primary;
		background-color: $dark-bcg-secondary; // ie
		background-color: var(--basicui-background-secondary);
        box-shadow: $box-shadow-primary;

        transform: translateY(5px);
        transition: transform $transition-primary;
        a {
            display flex;
            text-decoration: none;
            & + a {
                margin-top: 10px;
            }
        }

        &--show {
            transform: translateY(0px);    
        }
    }

    // <= 480px
    +media-phone-s() {
        &-menu {
            right 20px;    
            left: 20px;
            width: auto
        }
    }
}