.preloader {
	&--show {
		position: relative
		pointer-events: none
	}

	&-wrapper {
		top: 0px
		right: 0px
		bottom: 0px
		left: 0px
		position: absolute
		backdrop-filter: blur(2px)
		// background-color: rgba(255, 255, 255, 0.3)

		display: inline-flex
		align-items: center
		justify-content: center
	}

	&-container {
		display: inline-flex
		align-items: center
		justify-content: center

		z-index: 2
		width: 90px
		height: 90px
		position: relative
		border-radius: 100px
		box-shadow: $box-shadow-primary
		background-color: $light-bcg-secondary
		background-color: var(--background-secondary)
	}

	&-spinner {
		width: 80px;
		height: 80px;
		position: relative;
		display: inline-block;
		span {
			display: block;
			position: absolute;
			width: 64px;
			height: 64px;
			margin: 8px;
			border-radius: 50%;
			border: 8px solid #fff;
			border-color: #fff transparent transparent transparent;
			animation: preloader-anime 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			[data-color-scheme="light"] & {
				fill: $light-brand
				border: 8px solid $light-brand;
				border-color: $light-brand transparent transparent transparent;
			}

			&:nth-child(1) {
				animation-delay: -0.45s;
			}

			&:nth-child(2) {
				animation-delay: -0.3s;
			}

			&:nth-child(3) {
				animation-delay: -0.3s;
			}
		}
	}
}



@keyframes preloader-anime {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
