.group-triggers {
    left: 20px;
    bottom: 20px;
    position: fixed;
    &__item {
        width: 40px;
        height: 40px;
        padding: 0px;
        border: 0px;
        cursor: pointer;                           
        border-radius: 100px;
        transition: all $transition-primary;
        & + ^[-1]__item {
            margin-top: 10px;    
        }

        &:active {
            active()    
        }

        // dark-theme
        &.dark-theme {
            display: block;
            overflow: hidden;
            position: relative;
            .dt {
                &-checkbox {
                    top: 0;
                    left: -999px;
                    position: absolute;
                    opacity: 0;
                    width: .5px;
                    height: .5px;
                    &:checked + .dt-container {
                        transform: translate3d(0, 0, 0);       
                    }
                }

                &-container {
                    width: 40px;
                    height: 40px;  
                    transform: translate3d(0, -40px, 0);
                    transition: transform $transition-primary;
                }

                &-icon {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }            
            }

            .html-ie & {
                display: none;
            }
        }

        // hamburger
        &.sidebar-hamburger {
            display: none;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            span {
                margin: 3px 0px;
            }

            span,
            &:after,
            &:before {
                content: '';
                width: 16px;
                height: 2px;
                display: block;
                border-radius: 100px;
                background-color: #fff;
                transition: transform $transition-primary;
                [data-color-scheme="dark"] & {
                    background-color: var(--brand);
                }              
            }

            // <= 980px
            +media-table-m() {
                .doc & {
                    display: flex;                
                }
            }
        }
    }


    // <= 980px
    +media-table-m() {
        left: auto;
        right: 15px;
        bottom: 15px;
    }
}