.doc {
    &__row {
        display: flex;
        justify-content: center;
    }

    &__col {
        &.left {
            flex-grow: 1;
            width: calc(100% - 320px);
        }

        &.right {	    
            width: 260px;
            margin-left: 60px;
        }
    }


    // <= 980px
    +media-table-m() {
        &__row {
            flex-direction: column;
        }

        &__col {
            &.left {
                width: 100%;
            }

            &.right {
                margin: 0px;
                width: 280px;
            }
        }
    }
}